import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private titleService: Title, private router: Router) { }
  
  /**
   * On Init change the title
   */
  ngOnInit(): void {
    this.titleService.setTitle('404');      
  }

  /**
   * Go to the main landing page
   */
  goHome(): void {
    this.router.navigate([environment.routePaths.landing.home]);
  }
}
