import { Component } from '@angular/core';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-theme-selector',
  templateUrl: './theme-selector.component.html',
  styleUrls: ['./theme-selector.component.scss']
})
export class ThemeSelectorComponent {
  isDarkMode: boolean;
  light_mode_icon = faSun;
  dark_mode_icon = faMoon;

  constructor(private themeService: ThemeService) {
    this.isDarkMode = themeService.isDarkTheme();
  }

  switchTheme() {
    this.themeService.toggleTheme();
    this.isDarkMode = this.themeService.isDarkTheme();
  }
}
