import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ISchemas } from '../interfaces/firestoreSchemas';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { map, catchError, retryWhen, delay, mergeMap } from 'rxjs/operators';

const logPrefix = "[ApiService]";


export interface IApiResponse {
  message: string,
  data: any,
}


const getErrorMessage = (maxRetry: number) => `Tried to load Resource over XHR for ${maxRetry} times without success. Giving up.`;
const DEFAULT_MAX_RETRIES = 5;
export function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES) {
  let retries = maxRetry;
  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        delay(delayMs),
        mergeMap(error => retries-- > 0 ? of(error) : throwError(getErrorMessage(maxRetry)))
      ))
    );
}


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiToken: BehaviorSubject<string> = new BehaviorSubject("");
  canSendRequests: BehaviorSubject<boolean> = new BehaviorSubject(false);
  requestOptions: {} = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      "X_API_TOKEN": ""
    }
  };

  constructor(private httpClient: HttpClient) {
    this.apiToken.subscribe((apiToken) => {
      if (apiToken !== "") {
        console.log(`${logPrefix} API TOKEN CHANGED!!! Updating the API RequestOptions.`);
        this.requestOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "X_API_TOKEN": apiToken
          })
        }
        this.canSendRequests.next(true);
      }
    })
  }

  /**
   * Retrieves a user's details from the Firestore database.
   * @param uid The unique identifier for the user being retrieved.
   * @returns {Observable<ISchemas.User>}
   */
  getBrother(uid: string): Observable<ISchemas.Brother | {}> {
    const _logPrefix = logPrefix + "[getBrother]";
    const requestUrl: string = `${environment.api.endpoints.brothers}/${uid}`;
    console.log(`${_logPrefix} Request URL: ${requestUrl}`);
    console.log(`${_logPrefix} Attempting to retrieve details for the user with uid, '${uid}'.`);
    return this.httpClient.get(requestUrl, this.requestOptions).pipe(
      map((response: IApiResponse) => {
        console.log(`${_logPrefix} Successfully retrieved details for the user with uid, '${uid}').`);
        return <ISchemas.Brother>response.data;
      }), 
      catchError((error: any) => {
        console.log(`${_logPrefix} An error occurred while trying to retrieve details for the user with uid, '${uid}'. Error: ${JSON.stringify(error.error)}`);
        return throwError(error);
      })
      );
    }
  
  getBrothers(): Observable<ISchemas.Brother | {}> {
    const _logPrefix = logPrefix + "[getBrothers]";
    const requestUrl: string = `${environment.api.endpoints.brothers}`;
    console.log(`${_logPrefix} Attempting to retrieve all of the brother details`);
    return this.httpClient.get(requestUrl, this.requestOptions).pipe(
      map((response: IApiResponse) => {
        console.log(`${_logPrefix} Successfully retrieved all of the brother details`);
        return <ISchemas.Brother>response.data;
      }), 
      catchError((error: any) => {
        console.log(`${_logPrefix} An error occurred while trying to retrieve details for the brother details. Error: ${JSON.stringify(error.error)}`);
        return throwError(error);
      })
      );
    }
    
    /**
     * Simple utility function for updating the logged in user's lastLogin value.
     * @param uid The unique identifier for the user being updated.
     * @returns {Observable<ISchemas.User>}
    */
   updateBrotherLastLoginTime(uid: string): Observable<ISchemas.Brother> {
     const _logPrefix = logPrefix + "[updateBrotherLastLoginTime]";
     const requestUrl: string = `${environment.api.endpoints.brothers}/${uid}/logged-in`;
    console.log(`${_logPrefix} Attempting to update the lastLogin value for the brother with uid, '${uid}'.`);
    return this.httpClient.get(requestUrl, this.requestOptions).pipe(
      map((response: IApiResponse) => {
        console.log(`${_logPrefix} Successfully updated the lastLogin value for the brother (uid: ${response.data.uid ? response.data.uid : ''}).`);
        return <ISchemas.Brother>response.data;
      }),
      catchError((error) => {
        console.log(`${_logPrefix} An error occurred while trying to update the lastLogin value for the brother with uid, '${uid}'. Error: ${JSON.stringify(error)}`);
        return throwError(error);
      })
    )
  }

  /**
   * Simple utility function for updating the logged in user's lastLogin value.
   * @param uid The unique identifier for the user being updated.
   * @returns {Observable<ISchemas.User>}
   */
  createBrother(uid: string, email: string, displayName?: string, organizationName?: string): Observable<ISchemas.Brother> {
    const _logPrefix = logPrefix + "[createBrother]";
    const requestUrl: string = `${environment.api.endpoints.brothers}`;
    const payload = JSON.stringify({
      uid: uid,
      displayName: displayName,
      email: email,
      organizationName: organizationName,
    } as ISchemas.Brother);
    console.log(`${_logPrefix} Attempting to create a new user in the Firestore with the details: ${payload}`);
    return this.httpClient.post(requestUrl, payload, this.requestOptions).pipe(
      map((response: IApiResponse) => {
        console.log(`${_logPrefix} Successfully created a new user (uid: ${response.data.uid ? response.data.uid : ''}).`);
        return <ISchemas.Brother>response.data;
      }),
      catchError((error) => {
        console.log(`${_logPrefix} An error occurred while trying to create a new user in the Firestore. Error: ${JSON.stringify(error)}`);
        return throwError(error);
      })
    );
  }

    /**
   * Simple utility function for creating a new user.
   * @param newBrother The new brother to be added
   * @returns {Observable<ISchemas.User>}
   */
    createUser(newBrother: ISchemas.Brother ): Observable<ISchemas.Brother> {
      const _logPrefix = logPrefix + "[createBrother]";
      const requestUrl: string = `${environment.api.endpoints.brothers}`;
      const payload = JSON.stringify(newBrother);
      console.log(`${_logPrefix} Attempting to create a new user in the Firestore with the details: ${payload}`);
      return this.httpClient.post(requestUrl, payload, this.requestOptions).pipe(
        map((response: IApiResponse) => {
          console.log(`${_logPrefix} Successfully created a new user (uid: ${response.data.uid ? response.data.uid : ''}).`);
          return <ISchemas.Brother>response.data;
        }),
        catchError((error) => {
          console.log(`${_logPrefix} An error occurred while trying to create a new user in the Firestore. Error: ${JSON.stringify(error)}`);
          return throwError(error);
        })
      );
    }
  
  /**
   * Simple utility function for updating the logged in user's lastLogin value.
   * @param uid The unique identifier for the user being updated.
   * @returns {Observable<ISchemas.User>}
   */
  updateBrother(uid: string, updateData?: ISchemas.Brother): Observable<ISchemas.Brother> {
    const _logPrefix = logPrefix + "[updateBrother]";
    const requestUrl: string = `${environment.api.endpoints.brothers}/${uid}`;
    const payload = JSON.stringify(updateData);
    console.log(`${_logPrefix} Attempting to update the brother with uid, '${uid}', using these details: ${payload}`);
    return this.httpClient.put(requestUrl, payload, this.requestOptions).pipe(
      map((response: IApiResponse) => {
        console.log(`${_logPrefix} Successfully updated the brother with uid, ${uid}.`);
        return <ISchemas.Brother>response.data;
      }),
      catchError((error) => {
        console.log(`${_logPrefix} An error occurred while trying to update the brother with uid, '${uid}'. Error: ${JSON.stringify(error)}`);
        return throwError(error);
      })
    );
  }

  /**
   * Simple utility function for deleting the given user.
   * @param uid The unique identifier for the user being deleted.
   * @returns {Observable<ISchemas.User>}
   */
  deleteBrother(uid: string): Observable<ISchemas.Brother> {
    const _logPrefix = logPrefix + "[deleteBrother]";
    const requestUrl: string = `${environment.api.endpoints.brothers}/${uid}`;
    console.log(`${_logPrefix} Attempting to delete the brother with uid, '${uid}'`);
    return this.httpClient.delete(requestUrl, this.requestOptions).pipe(
      map((response: IApiResponse) => {
        console.log(`${_logPrefix} Successfully deleted the brother with uid, ${uid}.`);
        return <ISchemas.Brother>response.data;
      }),
      catchError((error) => {
        console.log(`${_logPrefix} An error occurred while trying to delete the brother with uid, '${uid}'. Error: ${JSON.stringify(error)}`);
        return throwError(error);
      })
    );
  }
}
