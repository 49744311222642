import { EnvironmentInterface, RoutePaths, ApiConfig } from "./environment-interface";

/*
  * For easier debugging in development mode, you can import the following file
  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
  *
  * This import should be commented out in production mode because it will have a negative impact
  * on performance if an error is thrown.
  */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

const apiKey = "AIzaSyCTLc2KjuukzG8W8wFBaIiiGRMtDhYoZC0";
const authDomain = "triangle-website-dev.firebaseapp.com";
const projectId = "triangle-website-dev";
const storageBucket = "triangle-website-dev.appspot.com";
const messagingSenderId = "695030848673";
const appId = "1:695030848673:web:7bad160952d21ffcfa64e0";
const measurementId = "G-22C80SXNP0";

export const environment: EnvironmentInterface = {
    production: true,
    firebase: {
      apiKey,
      authDomain,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
      measurementId
    },
    basePath: authDomain,
    routePaths: new RoutePaths(),
    api: new ApiConfig("https://api.vpitriangle.com/api"),
};