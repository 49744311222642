import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'landing-navbar',
  templateUrl: './landing-navbar.component.html',
  styleUrls: ['./landing-navbar.component.scss']
})
export class LandingNavbarComponent {
  showDropdown: boolean = true;
  homePath: string;
  aboutUsPath: string;
  serviceAndPhilanthropyPath: string;
  theBrothersPath: string;
  joinTodayPath: string;

  /**
   * Initialize the router paths
   */
  constructor(router: Router, viewportScroller: ViewportScroller) {
    this.homePath = environment.routePaths.landing.home;
    this.aboutUsPath = environment.routePaths.landing.aboutUs;
    this.serviceAndPhilanthropyPath = environment.routePaths.landing.serviceAndPhilanthropy;
    this.theBrothersPath = environment.routePaths.landing.theBrothers;
    this.joinTodayPath = environment.routePaths.landing.joinToday;
    
    if (window.innerWidth < 768) {
      this.showDropdown = false;
    }

    router.events.subscribe(()=>{
      viewportScroller.scrollToPosition([0, 0]);
    });
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    // If the window is greater than 800 pixels, open the sidebar
    this.showDropdown = window.innerWidth >= 768
    
  }

  toggleDropdown() {
    if (window.innerWidth < 768) {
      this.showDropdown = !this.showDropdown;
    }
  }
}
