<!-- [START] Navbar -->
<header aria-label="Header">
    <nav class="fixed top-0 z-10 flex flex-row
                items-center
                justify-between
                w-full
                py-4
                md:py-0
                px-4
                text-lg
                bg-lm-bg-2 text-lm-text-1
                dark:bg-dm-bg-1 dark:text-dm-text-1" aria-label="Navigation Bar">
        <!-- [START] Logo -->

        <div>
            <img class="rounded-t-lg w-full h-14 max-h-14 dark:invert" src="../../../../../assets/images/Triangle-Logo-Black.webp" alt="Triangle Logo" aria-label="Triangle Logo"/>
        </div>
        <!-- [END] Logo -->

        <!-- [START Right Side Group] -->
        <div class="group flex flex-col items-center">
            <!-- [START] Hamburger Menu Icon -->
            <button class="p-2 rounded-lg md:hidden" (click)="toggleDropdown()" aria-label="Toggle dropdown navigation menu">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="h-10 fill-current" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg>
            </button>
            <!-- [END] Hamburger Menu Icon -->

            <!-- [START] Tab Buttons -->
            <div *ngIf="showDropdown" class="mt-2 bg-lm-bg-2 text-lm-text-1 dark:bg-dm-bg-1 dark:text-dm-text-1 group-hover:block sm:block absolute sm:static inset-x-0 top-16 py-3 shadow-md sm:shadow-none" aria-label="Navigation menu">
                <!-- [START] Tab Options -->
                <div class="transition duration-150 -outflex flex-row justify-center items-center text-center">
                    <ul class="pt-4 md:flex md:justify-between md:pt-0">
                        <li>
                            <a (click)="toggleDropdown()" routerLink="{{homePath}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="sm:p-2 py-2 block hover:text-Old-Rose-400" aria-label="Home Page">Home</a>
                        </li>
                        <li>
                            <a (click)="toggleDropdown()" routerLink="{{aboutUsPath}}" routerLinkActive="active" class="sm:p-2 py-2 block hover:text-Old-Rose-400" aria-label="About Us Page">About Us</a>
                        </li>
                        <li>
                            <a (click)="toggleDropdown()" routerLink="{{serviceAndPhilanthropyPath}}" routerLinkActive="active" class="sm:p-2 py-2 block hover:text-Old-Rose-400" aria-label="Service and Philanthropy Page">Service and Philanthropy</a>
                        </li>
                        <li>
                            <a (click)="toggleDropdown()" routerLink="{{theBrothersPath}}" routerLinkActive="active" class="sm:p-2 py-2 block hover:text-Old-Rose-400" aria-label="The Brothers Page">The Brothers</a>
                        </li>
                        <li>
                            <a (click)="toggleDropdown()" href="https://docs.google.com/forms/d/e/1FAIpQLSdO-Qj6Kn1619xTVPQSeDNWp46dRyTYAgpP8I4wkSrOOxrX6A/viewform?usp=sf_link" target="_blank" class="sm:p-2 py-2 block hover:text-Old-Rose-400 text-Old-Rose-500" aria-label="Join Today Page">Join Today</a>
                        </li>
                    </ul>
                </div>
                <!-- [END] Tab Options -->
            </div>
            <!-- [END] Right Side Group -->
        </div>
    </nav>
</header>
<!-- [END] Navbar -->