<div class="w-full flex justify-center">
  <label class="flex items-center relative w-max cursor-pointer select-none">
    <input type="checkbox" class="appearance-none border transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-Old-Rose-500 bg-Old-Rose" (click)="switchTheme()" [checked]="isDarkMode" aria-label="Toggle light or dark mode for the site"/>
    <span class="absolute font-medium text-lg uppercase right-1 text-white">
      <fa-icon [icon]="light_mode_icon"></fa-icon>
    </span>
    <span class="absolute font-medium text-lg uppercase right-8 text-white">
      <fa-icon [icon]="dark_mode_icon"></fa-icon>
    </span>
    <span class="w-7 h-7 right-7 absolute rounded-full transform transition-transform bg-gray-200"></span>
  </label>
</div>