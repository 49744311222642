<!-- [START] Hamburger Menu to open for mobile -->
<span class="absolute text-white text-4xl top-4 left-4 cursor-pointer" (click)="openSidebar()">
   <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 50 50">
     <path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"></path>
   </svg>
 </span>
<!-- [END] Hamburger Menu -->

<div *ngIf="isOpen" class="sidebar sticky min-h-screen top-0 bottom-0 lg:left-0 p-2 w-72 overflow-y-auto text-center bg-gradient-to-br from-Old-Rose-100 to-Old-Rose-900">

    <!-- [START] Sidebar Header -->
    <div class="text-gray-100 text-xl">
        <div class="p-2.5 mt-1 flex items-center">
            <!--[START] Triangle Logo  -->
            <img class="mx-auto my-auto" src="../../../../../assets/images/Triangle-Logo-White.webp">
            <!-- [END] Triangle Logo -->

            <!-- [START] X-Icon -->
            <fa-icon [icon]="faClose" class="text-[20px] text-white cursor-pointer" (click)="closeSidebar()"></fa-icon>
            <!-- [END] X-Icon -->
        </div>
        <div class="my-2 bg-transparent h-[1px]"></div>
    </div>
    <!-- [END] Sidebar Header -->

    <!-- [START] Dashboard -->
    <div routerLink="{{dashboardPath}}" routerLinkActive="active" class="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-gray-500 text-white">
        <fa-icon [icon]="faHome" class="text-[24px] text-white"></fa-icon>
        <span class="text-[15px] ml-4 text-gray-200 font-bold">Dashboard</span>
    </div>
    <!-- [END] Dashboard -->

    <!-- [START] Brothers -->
    <div routerLink="{{brothersPath}}" routerLinkActive="active" class="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-gray-500 text-white">
        <fa-icon [icon]="faBrothers" class="text-[24px] text-white"></fa-icon>
        <span class="text-[15px] ml-4 text-gray-200 font-bold">Brothers</span>
    </div>
    <!-- [END] Brothers -->

    <!-- [START] Pledges -->
    <div routerLink="{{pledgesPath}}" routerLinkActive="active" class="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-gray-500 text-white">
        <fa-icon [icon]="faPledges" class="text-[24px] text-white"></fa-icon>
        <span class="text-[15px] ml-4 text-gray-200 font-bold">Pledges</span>
    </div>
    <!-- [END] Pledges -->

    <!-- [START] Admin Pages -->
    <div *ngIf="brotherDetails && brotherDetails.webRole == 'admin'">
        <div class="my-4 bg-gray-600 h-[1px]"></div>
        <div class="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-gray-500 text-white">
            <fa-icon [icon]="faAdminTools" class="text-[24px] text-white"></fa-icon>
            <span class="text-[15px] ml-4 text-gray-200 font-bold">
          Admin Tools
        </span>
        </div>
    </div>
    <!-- [END] Admin Pages -->

    <div class="my-4 bg-gray-600 h-[1px]"></div>

    <!-- [START] Profile -->
    <div routerLink="{{myProfilePath}}" routerLinkActive="active" class="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-gray-500 text-white">
        <fa-icon [icon]="faUser" class="text-[24px] text-white"></fa-icon>
        <span class="text-[15px] ml-4 text-gray-200 font-bold">
      {{brotherDetails ? brotherDetails.first_name : ""}} {{brotherDetails ? brotherDetails.last_name : ""}}
    </span>
    </div>
    <!-- [END] Profile -->

    <!-- [START] Logout -->
    <div (click)="logout()" class="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-gray-500 text-white">
        <fa-icon [icon]="faLogout" class="text-[24px] text-white"></fa-icon>
        <span class="text-[15px] ml-4 text-gray-200 font-bold">Logout</span>
    </div>
    <!-- [END] Logout -->
    <div class="absolute bottom-0 left-0 m-2">
        <app-theme-selector></app-theme-selector>
    </div>
</div>