interface IRoutePaths {
    landing: {
        home: string,
        aboutUs: string,
        serviceAndPhilanthropy: string,
        theBrothers: string,
        joinToday: string,
        brother: string,
    },
    login: {
        basePath: string,
        forgotPassword: string,
    },
    main: {
        basePath: string,
        dashboard: string,
        brothers: string,
        pledges: string,
        myProfile: string
        brother: string
    }
}
export class RoutePaths implements IRoutePaths {
    landing:IRoutePaths["landing"];
    login:IRoutePaths["login"];
    main:IRoutePaths["main"];
    
    /**
     * Initialize the routes (stored as field variables)
     * 
     * @param landing The landing container routes
     * @param login The login container routes
     * @param home The home container route 
     */
    constructor(landing?: IRoutePaths["landing"], login?: IRoutePaths["login"], main?: IRoutePaths["main"]) {
        // Initialize Landing Routes
        if (landing) {
            this.landing = landing;
        }
        else {
            this.landing = {
                home: "",
                aboutUs: "about-us",
                serviceAndPhilanthropy: "service-and-philanthropy",
                theBrothers: "the-brothers",
                joinToday: "join-today",
                brother: "brother"
            };
        }
        
        // Initialize Login Routes
        if (login) {
            this.login = login;
        }
        else {
            this.login = {
                basePath: "login",
                forgotPassword: "forgot-password"
            };
        }

        // Initialize Main Routes
        if (main) {
            this.main = main;
        }
        else {
            this.main = {
                basePath: "main",
                dashboard: "dashboard",
                brothers: "brothers",
                pledges: "pledges",
                myProfile: "my-profile",
                brother: "brother"
            }
        }
    }
}

interface IApiConfig {
    basePath: string;
    endpoints: object;
}
export class ApiConfig implements IApiConfig {
    basePath;
    endpoints;

    constructor(basePath?: string) {
        this.basePath = basePath ? basePath : "http://localhost:8080/api";
        this.endpoints = {
            brothers: this.basePath + "/brothers",
        }
    }
}


export interface EnvironmentInterface {
    production: boolean;
    firebase: {
        apiKey: string,
        authDomain: string,
        projectId: string,
        storageBucket: string,
        messagingSenderId: string,
        appId: string,
        measurementId: string,
    };
    basePath: string;
    routePaths: RoutePaths;
    api: ApiConfig;
}
