import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AngularFireAuthGuard, redirectUnauthorizedTo} from '@angular/fire/compat/auth-guard';

// Landing Modules
import { LandingContainer } from './landing-container/landing-container.component';

// Login Modules
import { LoginContainer } from './login-container/login-container.component';

// Home Modules
import { MainContainer } from './main-container/main-container.component';

// 404
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { BrotherLandingPageComponent } from './shared/components/landing-container/brother-landing-page/brother-landing-page.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([environment.routePaths.login.basePath]);

const routes: Routes = [
    // Landing Page Container
    {
        path: environment.routePaths.landing.home,
        component: LandingContainer,
        loadChildren: () => import('./landing-container/landing-container.module').then (
            m => m.LandingContainerModule
        )
    },
    
    // Login Page Container
    {
        path: environment.routePaths.login.basePath,
        component: LoginContainer,
        loadChildren: () => import('./login-container/login-container.module').then (
            m => m.LoginContainerModule
        )
    },
    
    // Main Container (auth users only)
    {
        path:  environment.routePaths.main.basePath,
        component: MainContainer,
        loadChildren: () => import('./main-container/main-container.module').then (
            m => m.MainContainerModule
        ),
        canActivate: [AngularFireAuthGuard], 
        data: { authGuardPipe: redirectUnauthorizedToLogin }
    },

    {
        path: environment.routePaths.landing.brother + '/:id',
        component: BrotherLandingPageComponent
    },

    // Default Page goes to the main page
    {
        path: '',
        redirectTo: environment.routePaths.landing.home,
        pathMatch: 'full',
    },

    // 404 Error
    {
        path: '**',
        component: PageNotFoundComponent
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }