import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  // Dark mode will be enabled by default
  private isDarkMode = true;

  constructor() {
    const savedTheme = localStorage.getItem('theme');
    // Set the theme to the user's last selection
    if (savedTheme === 'light') {
      this.toggleLight();
    }
    // Toggle dark mode by default
    else {
      this.toggleDark();
    }
  }

  /**
   * Toggle the theme from lightmode to dark mode
   */
  toggleTheme(): void {
    this.isDarkMode = !this.isDarkMode;
    if (this.isDarkMode) {
      this.toggleDark();
    }
    else {
      this.toggleLight();
    }
  }

    /**
    * Set the theme to dark
    */
    private toggleDark() {
      localStorage.setItem('theme', 'dark');
      this.isDarkMode = true;
      document.documentElement.classList.remove('light');
      document.documentElement.classList.add('dark');
    }
  
    /**
     * Set the theme to light
     */
    private toggleLight() {
      localStorage.setItem('theme', 'light');
      this.isDarkMode = false;
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');
    }


  /**
   * Check what the current theme status is
   * 
   * @returns true if the site is in dark mode
   */
  isDarkTheme(): boolean {
    return this.isDarkMode;
  }
}