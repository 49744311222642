<div class="flex">
    <main-sidebar></main-sidebar>

    <!-- [START] Content Section -->
    <div class="flex flex-col w-screen">

        <div class="overflow-y-auto p-2">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- [END] Content Section -->
</div>