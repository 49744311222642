import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ISchemas } from 'src/app/shared/interfaces/firestoreSchemas';
import { Title } from '@angular/platform-browser';

import {
  faGraduationCap,
  faEnvelope,
  faHome,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'brother-landing-page',
  templateUrl: './brother-landing-page.component.html',
  styleUrls: ['./brother-landing-page.component.scss'],
})
export class BrotherLandingPageComponent implements OnInit {
  brother: ISchemas.Brother = {};
  brother_id;
  loading: boolean = true;

  faGraduationCap = faGraduationCap;
  faEnvelope = faEnvelope;
  faHome = faHome;
  faLinkedin = faLinkedin as IconDefinition;

  constructor(
    private router: Router,
    private firestore: AngularFirestore,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    // Get the supplier id from the url
    const url_segments: UrlSegment[] = this.activatedRoute.snapshot.url;
    this.brother_id = url_segments[url_segments.length - 1].toString();
  }

  ngOnInit(): void {
    // Grab the brother details for firebase
    const pattern: RegExp = /^([A-Za-z]+)-([A-Za-z]+)$/;
    const match: RegExpMatchArray | null = this.brother_id.match(pattern);
    if (match) {
      this.firestore
        .collection('brothers')
        .ref.where('first_name', '==', this.capitalizeFirstLetter(match[1])) //TODO: Fix for James' name McKinley
        .where('last_name', '==', this.capitalizeFirstLetter(match[2]))
        .get()
        .then(
          (doc) => {
            if (!doc.empty) {
              this.brother = doc.docs[0].data();
              this.loading = false;

              // Set the tab title
              this.titleService.setTitle(
                `${this.brother.first_name} ${this.brother.last_name}`
              );
            } else {
              this.redirectOnError();
            }
          },
          (error) => {
            this.redirectOnError();
          }
        );
    } else {
      this.redirectOnError();
    }
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * If the brother id is not found in the database, then
   * redirect the user back to the brother page after n seconds
   */
  redirectOnError(): void {
    this.router.navigate(['the-brothers']);
  }
}
