import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

// Firebase related imports
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

// Guard and 404 component
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { EnsureModuleLoadedOnceGuard } from './guards/ensure-module-loaded-once.guard';

const firebaseConfig = {
  apiKey: "AIzaSyCTLc2KjuukzG8W8wFBaIiiGRMtDhYoZC0",
  authDomain: "triangle-website-dev.firebaseapp.com",
  projectId: "triangle-website-dev",
  storageBucket: "triangle-website-dev.appspot.com",
  messagingSenderId: "695030848673",
  appId: "1:695030848673:web:7bad160952d21ffcfa64e0",
  measurementId: "G-22C80SXNP0"
};

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    
    // Firebase related imports
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  declarations: [
    PageNotFoundComponent,
  ],
  exports: [],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard{ 
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Use the EnsureModuleLoadedOnceGuard constructor to check that the core module was not imported outside of root
    super(parentModule);
  }
}
