<div class="flex flex-col bg-gradient-to-br from-Old-Rose-100 to-Old-Rose-900">
    <landing-navbar></landing-navbar>

    <!-- [START] Landing Container Contents-->
    <div>
        <router-outlet></router-outlet>
    </div>
    <!-- [END] Landing Container Contents  -->

    <landing-footer></landing-footer>
</div>