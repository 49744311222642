import { Component, OnInit, HostListener } from '@angular/core';
import { ISchemas } from 'src/app/shared/interfaces/firestoreSchemas';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import {
  faHome,
  faUser,
  faArrowRightFromBracket,
  faBaby,
  faUsers,
  faCircleXmark,
  faScrewdriverWrench,
} from '@fortawesome/free-solid-svg-icons';

const logPrefix = '[MainSidebarComponent]';

@Component({
  selector: 'main-sidebar',
  templateUrl: './main-sidebar.component.html',
  styleUrls: ['./main-sidebar.component.scss'],
})
export class MainSidebarComponent implements OnInit {
  isOpen: boolean;
  brotherDetails: ISchemas.Brother;

  // Icons
  faUser = faUser;
  faHome = faHome;
  faBrothers = faUsers;
  faPledges = faBaby;
  faClose = faCircleXmark;
  faAdminTools = faScrewdriverWrench;
  faLogout = faArrowRightFromBracket;

  // Paths
  dashboardPath: string = environment.routePaths.main.dashboard;
  brothersPath: string = environment.routePaths.main.brothers;
  pledgesPath: string = environment.routePaths.main.pledges;
  myProfilePath: string = environment.routePaths.main.myProfile;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // Only open the sidebar on initialization if the screen size is small
    if (window.innerWidth > 800) {
      this.openSidebar();
    }

    // Get the current user and set the brother's details
    this.authService.getUser().then((user) => {
      user.subscribe((brother) => {
        this.brotherDetails = brother;
      });
    });
  }

  /**
   * When the window is resized, then either show or hide the sidebar
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(`${logPrefix} Window resize detected`);

    // If the window is greater than 800 pixels, open the sidebar
    if (window.innerWidth > 800) {
      this.openSidebar();
    }
    else{
      this.closeSidebar();
    }
  }

  logout() {
    this.authService.SignOut().then(()=>{
      this.router.navigate([environment.routePaths.login.basePath]);
    });
  }

  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }

  openSidebar() {
    const logMsg = `${logPrefix} Opening sidebar...`;
    this.isOpen = true;
    console.log(logMsg);
  }
  closeSidebar() {
    const logMsg = `${logPrefix} Closing sidebar...`;
    this.isOpen = false;
    console.log(logMsg);
  }
}
