<!-- Footer section with social media icons and newsletter sign-up -->
<div style="clear: both"></div>
<footer class="full bg-neutral-900 text-center text-white">
    <div class=" px-6 pt-6 place-content-center m-0">
        
        <!-- Copyright information -->
        <div class="mb-6 w-full">
            <div class="my-auto flex justify-center" aria-label="Triangle Logo">
                <img class="rounded-t-lg h-auto my-auto max-h-14" src="../../../../../assets/images/Triangle-Logo-White.webp" alt="Triangle Logo" />
            </div>
            <p class="italic" aria-label="Triangle Motto">"Pay the price of success in honest effort."</p>
        </div>

        <!-- Social media icons container -->
        <div class="mb-6 flex justify-center" aria-label="Social Media Container">

            <!-- [START] Facebook Button -->
            <a href="https://www.facebook.com/vttriangle/?fref=ts" target="_blank" type="button" class="m-1 h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0" data-te-ripple-init data-te-ripple-color="light" aria-label="Facebook Button">
                <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-full w-4" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                </svg>
            </a>
            <!-- [END] Facebook Button -->

            <!-- [START] Instagram Button -->
            <a href="https://www.instagram.com/vttriangle/" type="button" target="_blank" class="m-1 h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0" data-te-ripple-init data-te-ripple-color="light" aria-label="Instagram Button">
                <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-full w-4" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
            </a>
            <!-- [END] Instagram Button -->

            <!-- [START] LinkedIn Button -->
            <a href="https://www.linkedin.com/company/vttriangle/mycompany/" target="_blank" type="button" class="m-1 h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0" data-te-ripple-init data-te-ripple-color="light" aria-label="LinkedIn Button">
                <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-full w-4" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                </svg>
            </a>
            <!-- [END] LinkedIn Button -->
        </div>

        <!-- Links section -->
        <div class="flex flex-col justify-center gap-4 sm:flex-row md:flex-row">

            <div class="mb-6">
                <h2 class="mb-2.5 font-bold uppercase underline">VPI Triangle Links</h2>

                <ul class="mb-0 list-none" >
                    <li class="mb-2">
                        <a routerLink="{{loginPath}}" class="text-white" aria-label="Go to brothers login page">Brother Login</a>
                    </li>
                    <li class="mb-2">
                        <a href="https://chapterspot.triangle.org/home" target="_blank" class="text-white" aria-label="Go to chapter spot login">Chapter Spot</a>
                    </li>
                    <li class="mb-2">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeOtxjOykl4-tksQQ49FfIENT1HZ24NfnibU0RQbXJf1A5-cA/viewform?usp=send_form" target="_blank" class="text-white" aria-label="Go to rush interest form">Interest Form</a>
                    </li>
                </ul>
            </div>


            <div class="mb-6">
                <h2 class="mb-2.5 font-bold uppercase underline">Triangle National Links</h2>

                <ul class="mb-0 list-none">
                    <li class="mb-2">
                        <a href="https://www.triangle.org/" target="_blank" class="text-white" aria-label="Go to Triangle National website">Nationals Website</a>
                    </li>
                    <li class="mb-2">
                        <a href="https://www.triangle.org/national-leadership/national-council/" target="_blank" class="text-white" aria-label="Go to National Council page">National Council</a>
                    </li>
                    <li class="mb-2">
                        <a href="https://www.triangle.org/code-of-ethics/" target="_blank" class="text-white" aria-label="Go to code of ethics">Code of Ethics</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- Copyright section -->
    <div style="background-color: rgba(0, 0, 0, 0.2) py-1">
        <app-theme-selector></app-theme-selector>
        <div class="w-full text-center pt-2">
            © 2023 Copyright: VPI Triangle
        </div>
    </div>
</footer>