<div class="bg-gradient-to-br from-Old-Rose-100 to-Old-Rose-900">
  <div class="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
    <div class="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
      <div class="border-t border-gray-200 text-center pt-8">
        <h1 class="text-6xl font-bold text-Old-Rose">404</h1>
        <h1 class="text-3xl font-medium py-8">Oops! Page not found</h1>
        <p class="text-xl pb-8 px-12 font-medium">The page you are looking for does not exist. It might have been
          moved or deleted.</p>
        <button (click)="goHome()" class="bg-Old-Rose hover:bg-Old-Rose-200 text-white font-semibold px-6 py-3 rounded-md mr-6">
          HOME
        </button>
      </div>
    </div>
  </div>
</div>
