import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss']
})
export class LandingFooterComponent {
  loginPath: string;
  color_theme_icon;
  current_color_theme;

  /**
   * Initialize the path
   */
  constructor(private themeService: ThemeService) {
    this.loginPath = environment.routePaths.login.basePath;
    this.current_color_theme = themeService.isDarkTheme() ? 'Dark' : 'Light'; 
  }

  /**
   * Switch the theme form dark->light
   * or light -> dark
   */
  switchTheme() {
    this.themeService.toggleTheme();
    this.current_color_theme = this.themeService.isDarkTheme() ? 'Dark': 'Light';
  }
}
