import { Router } from '@angular/router'
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider } from '@angular/fire/auth';
import firebase from 'firebase/compat/app';

import { environment } from 'src/environments/environment';
import { ISchemas } from '../interfaces/firestoreSchemas';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import * as firebaseAuthErrorCodesJSON from "./firebaseAuthErrorCodes.json";

const logPrefix = "[AuthService]";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  brotherDetails: BehaviorSubject<ISchemas.Brother> = new BehaviorSubject(null);
  firebaseAuthErrorCodes: object;

  /**
   * AuthService constructor.
   * @param afAuth a Firebase Authentication object via AngularFireAuth.
   * @param router the app's Angular router.
   */
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private apiService: ApiService
  ) {
    // Load in the static file containing all Firebase Auth Error Codes.
    this.firebaseAuthErrorCodes = firebaseAuthErrorCodesJSON;
    // Subscribe to changes with a user's authState (logged in or not).
    this.afAuth.authState.subscribe((user?: firebase.User) => {
      if (user) {
        // A user is logged in.
        console.log(
          `${logPrefix} The user, ${user.displayName} (email: ${user.email}, uid: ${user.uid}) is already logged in. Updating details.`
        );
        user.getIdToken().then((apiToken: string) => {
          this.apiService.apiToken.next(apiToken);
          this.apiService
            .getBrother(user.uid)
            .subscribe((retrievedUser: ISchemas.Brother) => {
              if (retrievedUser.emailVerified !== user.emailVerified) {
                this.apiService
                  .updateBrother(user.uid, {
                    emailVerified: user.emailVerified,
                  })
                  .subscribe((updatedUser: ISchemas.Brother) => {
                    user.updateProfile({
                      displayName: updatedUser.displayName,
                    });
                    this.brotherDetails.next(updatedUser);
                  });
              } else {
                user.updateProfile({ displayName: retrievedUser.displayName });
                this.brotherDetails.next(retrievedUser);
              }
            });
        });
      } else {
        // NO user is logged in.
        console.log(`${logPrefix} No user is logged in.`);
        this.apiService.apiToken.next('');
        this.brotherDetails.next(null);
      }
    });
  }

  /**
   * Checks if a user is logged in.
   *
   * Logic: In order for a user to be considered logged in, the UID of the user
   * returned by afAuth's currentUser method must match the UID of the user in
   * local storage AND the emailVerified value of the user must be true.
   *
   * @returns {Promise<boolean>} If a user is logged in, true is returned in the
   *  Promise. Otherwise, false is returned.
   */
  async userIsLoggedIn(): Promise<boolean> {
    let _logPrefix = logPrefix + '[userIsLoggedIn]';
    console.log(`${_logPrefix} Checking if a user is logged in.`);
    const currentUser: firebase.User = await this.afAuth.currentUser;
    const isLoggedIn: boolean = currentUser.uid && currentUser.emailVerified;
    console.log(`${_logPrefix} Logged in = ${isLoggedIn}.`);
    return isLoggedIn;
  }

  /**
   * Retrieves the currently logged in user
   * @returns {Promise<Observable<ISchemas.Brother>>} Returns an observable of the currently logged in user
   */
  async getUser(): Promise<Observable<ISchemas.Brother>> {
    return await this.brotherDetails.asObservable();
  }

  /**
   * Calls afAuth's signInWithEmailAndPassword method to sign in the user and
   * takes action accordingly.
   * @param email The email to use to attempt to sign in.
   * @param password The password to use to attempt to sign in.
   * @returns {Promise<ISchemas.User>} If sign in is successful, the corresponding User
   *  object is returned in a Promise. Otherwise, the error message explainingd
   *  what went wrong is returned.
   */
  SignIn(email: string, password: string): Promise<ISchemas.Brother> {
    const _logPrefix = logPrefix + '[SignIn]';
    console.log(
      `${_logPrefix} Attempting to sign in with email, ${email}, and password, ${password}.`
    );
    return new Promise((resolve, reject) => {
      this.afAuth
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          console.log(`${_logPrefix} Successfully signed in.`);
          const user: firebase.User = result.user;
          user.getIdToken().then((apiToken: string) => {
            this.apiService.apiToken.next(apiToken);
            this.apiService
              .updateBrotherLastLoginTime(user.uid)
              .subscribe((updatedUser: ISchemas.Brother) => {
                console.log(
                  `${_logPrefix} Successfully updated last login details`
                );
                user.updateProfile({ displayName: updatedUser.displayName });
                this.brotherDetails.next(updatedUser);
                resolve(updatedUser);
              });
          });
        })
        .catch((error) => {
          let errorMessage = '';
          if (
            error.code === 'auth/wrong-password' ||
            error.code == 'auth/user-not-found'
          ) {
            errorMessage = 'Incorrect email or password';
          } else {
            errorMessage = this.firebaseAuthErrorCodes[error.code];
          }
          reject(errorMessage);
        });
    });
  }

  /**
   * Calls afAuth's createUserWithEmailAndPassword method to create a new user
   * in Firebase and takes action accordingly.
   * @param firstName The user's first name.
   * @param lastName The user's last name.
   * @param email The user's email that their new account will be linked to.
   * @param password The user's password for their new account.
   * @returns {Promise<User>} If the user is successfully created, the new
   *  User object is returned in a Promise. Otherwise, the error message
   *  explaining what went wrong is returned.
   */
  SignUp(
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ): Promise<ISchemas.Brother> {
    const _logPrefix = logPrefix + '[SignUp]';
    const displayName = (firstName + ' ' + lastName)
      .replace(/\s+/g, ' ')
      .trim();
    console.log(
      `${_logPrefix} Attempting to sign up a new user with displayName, ${displayName}, email, ${email}, and password, ${password}.`
    );
    return new Promise((resolve, reject) => {
      this.afAuth
        .createUserWithEmailAndPassword(email, password)
        .then((result) => {
          if (result) {
            console.log(`${_logPrefix} Successfully signed up.`);
            const user: firebase.User = result.user;
            user.getIdToken().then((apiToken: string) => {
              this.apiService.apiToken.next(apiToken);
              this.apiService
                .createBrother(user.uid, user.email, displayName)
                .subscribe(
                  // TODO: Change this once there is functionality for organizations.
                  (newUser: ISchemas.Brother) => {
                    user.updateProfile({ displayName: displayName });
                    this.SendVerificationEmail({
                      url: `${environment.basePath}/${environment.routePaths.main.basePath}`,
                    });
                    this.brotherDetails.next(newUser);
                    resolve(newUser);
                  }
                );
            });
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          let errorMessage = '';
          if (errorCode == 'auth/weak-password') {
            errorMessage = 'The password is too weak.';
          } else {
            errorMessage = error.message;
          }
          reject(errorMessage);
        });
    });
  }

  /**
   * Calls apiService createUser to handle storing data in fire base
   * @param newUser The user object.
   * @returns {Promise<ISchemas.Brother>} If the user is successfully created, the new
   *  Brother object is returned in a Promise. Otherwise, the error message
   *  explaining what went wrong is returned.
   */
  CreateUser(newUser: ISchemas.Brother): Promise<ISchemas.Brother> {
    const _logPrefix = logPrefix + '[CreateUser]';
    const displayName = (newUser.first_name + ' ' + newUser.last_name)
      .replace(/\s+/g, ' ')
      .trim();
    console.log(
      `${_logPrefix} Attempting to create a new user with displayName, ${displayName}, email, ${newUser.email_school}.`
    );
    newUser.uid = firebase.firestore().collection('name').doc().id; //Creates a random uid
    return new Promise((resolve, reject) => {
      this.apiService.createUser(newUser).subscribe(
        (newUser: ISchemas.Brother) => {
          resolve(newUser);
        },
        (error) => {
          reject(error.message);
        }
      );
    });
  }

  /**
   * Sends a verification email to the currently logged in user.
   * @param actionCodeSettings Object containing additional settings for the
   *  afAuth's sendEmailVerification method.
   * @returns {Promise<boolean>} If sent successfully, true is returned in the
   *  Promise. Otherwise, false is returned.
   */
  SendVerificationEmail(actionCodeSettings: object): Promise<boolean> {
    const _logPrefix = logPrefix + '[SendVerificationEmail]';
    return new Promise((resolve, reject) => {
      this.afAuth.currentUser
        .then((user: firebase.User) => {
          console.log(
            `${_logPrefix} Attempting to send a verification email to ${user.displayName} (email: ${user.email}, uid: ${user.uid}).`
          );
          user.sendEmailVerification(
            actionCodeSettings as firebase.auth.ActionCodeSettings
          );
          this.SignOut();
          resolve(true);
        })
        .catch((error) => {
          // TODO: Change this logic once you know more.
          window.alert(`${_logPrefix} ${error.message}`);
          reject(error);
        });
    });
  }

  /**
   * Sends an email to the provided email address with instructions to
   * reset their account password.
   * @param passwordResetEmail The email to send the reset password email to.
   * @returns {Promise<boolean>} If sent successfully, true is returned in the
   *  Promise. Otherwise, false is returned.
   */
  SendForgotPasswordEmail(passwordResetEmail: string): Promise<boolean> {
    const _logPrefix = logPrefix + '[SendForgotPasswordEmail]';
    console.log(
      `${_logPrefix} Attempting to send a reset password email to the email, ${passwordResetEmail}.`
    );
    return new Promise((resolve, reject) => {
      this.afAuth
        .sendPasswordResetEmail(passwordResetEmail)
        .then(() => {
          console.log(
            `${_logPrefix} Successfully sent the reset password email to ${passwordResetEmail}.`
          );
          resolve(true);
        })
        .catch((error) => {
          // TODO: Change this logic once you know more.
          console.log(
            `${_logPrefix} An error occurred when trying to send a password reset email. Error: ${error.message}`
          );
          reject(error);
        });
    });
  }

  // TODO: Integrate this functionality into the existing app.
  /**
   * Uses afAuth's built-in sign in/up with Google functionality to sign in, or
   * up, a user using their Google account.
   */
  SignInOrUpWithGoogle(): void {
    this.ProviderLogin(new GoogleAuthProvider());
  }

  // TODO: Integrate this functionality into the existing app.
  /**
   * Reusable function for sign in/up with the provided Firebase Auth provider.
   * @param provider Any valid Firebase Auth provider (ex: Google, Facebook,
   *  GitHub, Twitter, Apple, etc.).
   * @returns {Promise<any>}
   */
  ProviderLogin(provider: any): Promise<any> {
    console.log(
      `${logPrefix} At AuthService.AuthLogin() where provider = ${provider}.`
    );
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.router.navigate([environment.routePaths.main.basePath]);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  /**
   * Signs out the logged in user, clears the local storage of any user data,
   * then navigates the user back to the Login page.
   */
  async SignOut(): Promise<void> {
    const _logPrefix = logPrefix + '[SignOut]';
    console.log(`${_logPrefix} Attempting to sign out the logged in user.`);
    await this.afAuth.signOut(); // Using 'await' because we don't want to do anything until we know the user is logged out.
    console.log(
      `${_logPrefix} Successfully signed out the logged in user. Clearing the local storage and returning the user to the Login page.`
    );
    this.router.navigate([environment.routePaths.main.basePath]);
  }
}
